export default {
  nl2br: function(s){
    if (s) {
      return s.replace(/\n/g,'<br/>')
    } else return ''
  },
  autoLink(str) {
    if (str) {
      var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
      var regexp_makeLink = function(all, url, h, href) {
        return '<a href="h' + href + '" target="_blank">' + url + '<span class="icon"><i class="mdi mdi-open-in-new"></i></span></a>';
      }
      return str.replace(regexp_url, regexp_makeLink);
    } else return str
  },
}
