export default {
    dateFormatter(dt) {
        if (dt) { 
          var y = dt.getFullYear()
          var m = ('00' + (dt.getMonth()+1)).slice(-2)
          var d = ('00' + dt.getDate()).slice(-2)
          return (y + '-' + m + '-' + d)
        } else {
          return null
        }
      },
      dateFromToFormatter(dt) {
        if (dt && dt.length == 2) { 
          const dt1 = dt[0].getFullYear() + '-' + ('00' + (dt[0].getMonth()+1)).slice(-2) + '-' + ('00' + dt[0].getDate()).slice(-2)
          const dt2 = dt[1].getFullYear() + '-' + ('00' + (dt[1].getMonth()+1)).slice(-2) + '-' + ('00' + dt[1].getDate()).slice(-2)
          return dt1 + ' ～ ' + dt2
        } else {
          return ''
        }
      },    
}
