export default {
  props: {
  },
  methods: {
    authCheck: async function(disp_redirect_message=true) {
      let _this = this
      let tmpValue = {}
      await this.axios
        .get('/api/auth/check/')
          .then(function (response) {
            tmpValue = response.data
            if ( _this.$version != response.data.version){
              _this.reloadInformation(response.data.version)
            }            
        }.bind(tmpValue))
        .catch(error => {
          if (error.response && error.response.status && error.response.status == '403') {
            console.log('getAxios: 403 Error: authCheck')
            if (this.$route.name !== 'login') {
              this.$router.push({name: 'login', params: {forward_to: this.$route.fullPath, disp_redirect_message: disp_redirect_message}}).catch(error => {
                console.log(error.message + ' : authCheck')
             })
            }
          }
          else if (error.response && error.response.data && error.response.data.errors) {
            const error_list = error.response.data.errors
            // console.log('error contents: ' + error_list)
            let error_msg = ''
            error_list.forEach(function(error) {
              error_msg+='<li>' + error.message + ' code:' + error.code + '</li>'
            })
            // console.log('error_msg: ' + error_msg)
            if (error_msg) error_msg = '<div class="content is-small"><ul style="list-style-type:none;text-align:left;">' + error_msg + '</ul></div>'
            this.$buefy.toast.open({
              message: '認証情報の取得に失敗しました。' + error_msg,
              type: 'is-danger',
              position: 'is-top',
              duration: 4000
            })
            setTimeout(() => {
            }, 1.5 * 1000)
            return Promise.reject(error)
          }
        })
        return tmpValue;
    },
    logout: async function() {
      // console.log('logout Called...')
      let _this = this
      let tmpValue = {}
      await this.axios
        .post('/api/logout/')
          .then(function (response) {
            tmpValue = response.data
            _this.$router.push({name: 'login', params: {forward_to: '/', disp_redirect_message: false}})
        }.bind(tmpValue))
        .catch(error => {
          // console.log('logout: ' + error)
          let error_msg = ''
          if (error.response && error.response.status && error.response.status == '403') {
            if (error.response && error.response.data && error.response.data.errors) {
              // console.log('errorContents: ' + JSON.stringify(error.response.data.errors))
            }
            this.$router.push({name: 'login', params: {forward_to: '/', disp_redirect_message: false}})
            return Promise.reject(error)
          }
          else if (error.response && error.response.data && error.response.data.errors) {
            const error_list = error.response.data.errors
            // console.log('error contents: ' + error_list)
            error_list.forEach(function(error) {
              error_msg+='<li>' + error.message + ' code:' + error.code + '</li>'
            })
          }
          // console.log('error_msg: ' + error_msg)
          if (error_msg) error_msg = '<div class="content is-small"><ul style="list-style-type:none;text-align:left;">' + error_msg + '</ul></div>';
          this.$buefy.toast.open({
            message: 'ログアウトに失敗しました。' + error_msg,
            type: 'is-danger',
            position: 'is-top',
            duration: 4000
          })
          return Promise.reject(error)
        })
        setTimeout(() => {
        }, 1.5 * 1000)
        return tmpValue;
    },
    reloadInformation(version) {
      this.indefinteToast = this.$buefy.toast.open({
          indefinite: true,
          message: `アプリケーションの更新を確認しました。<br>ページの再読込みを行い、最新バージョン <strong>ver.` + version + `</strong> に更新されたことを確認してください。`,
          type: 'is-warning'
      })
    },
  }
}
  