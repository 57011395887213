export default {
    displayListNumber(list, no_limit) {
        const num = list.length
        if (no_limit) {
          return Number(num).toLocaleString() + '件'
        } else if (num >= 1000) {
          return '1,000件以上'
        }
        else return Number(num).toLocaleString() + '件'
      },
      isLargerThan1000(list, no_limit) {
        const num = list.length
        if (no_limit) {
          return false
        } else if (num >= 1000) {
          return true
        }
        else return false
      },
}
