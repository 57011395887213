<template>
  <div id="app">
    <router-view />
    <FooterNav></FooterNav>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterNav from '@/components/FooterNav'

export default {
  name: 'HomeView',
  components: {
    FooterNav,
  },
  props: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/app";
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}
// Title
.title-link a {
  color: hsl(0deg, 0%, 21%)!important;
  text-decoration: underline;
}
.v-adjust {
  vertical-align:super;
}
// common
.transparent {
	color:transparent;
}
.reverse-row-order {
  flex-direction: row-reverse;
}
// sidebar
.b-sidebar {
  .sidebar-content {
    &.is-fixed {
      top: 60px;
    }
  }
}
// footer
footer {
  margin-top: auto;
}
// Panel
.panel-block {
  background-color: hsl(0deg, 0%, 100%);
  // background-color: #fafafa;
}
.box {
  background-color: hsl(0deg, 0%, 100%);
  // background-color: #fafafa;
}
// Tabs
.b-tabs {
  .tab-content {
    padding: 1rem 0rem;
  }
  .is-boxed > ul > li {
    box-sizing: unset;
  }
  .is-vertical {
      flex-wrap: nowrap;
  }
}
.hiddenTab .tabs {
    display: none;
}
// table
.striped-color {
  background-color: #f9f9f9;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.minimum-td {
  font-size: 0.8em;
  line-height: 0.5;
}
// v-model 内の改行をちゃんと改行させる
.disp-areas {
  white-space: pre-line;
}
// Form
.button.is-label {
    background-color: transparent;
    border-color: transparent;
    color: hsl(0, 0%, 21%)!important;
    text-decoration: none;
    cursor: auto;
    opacity: 1.0;
}
// 数値フィールドに spin を表示しない
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}
[disabled].input, [disabled].textarea, [disabled].taginput-container.is-focusable, .taginput [disabled].taginput-container.is-focusable {
  background-color: hsl(0deg, 0%, 98%)!important;
  color: hsl(0deg, 0%, 21%);
  cursor: initial;
}
.disabled-textarea {
  padding: calc(0.75em - 1px);
  background-color: hsl(0, 0%, 98%) !important;
  color: hsl(0, 0%, 21%);
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid transparent;
  border-color: hsl(0, 0%, 86%);
  cursor: initial;
}
.select select[disabled], .taginput [disabled].taginput-container.is-focusable, [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .taginput .taginput-container.is-focusable, .taginput fieldset[disabled] .taginput-container.is-focusable, fieldset[disabled] .textarea, fieldset[disabled] .input {
    border-color: hsl(0deg, 0%, 86%);
}
select {
  min-width: 120px;
}
.short-datepicker {
  max-width: 160px;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.width60 {
  width: 60px!important;
}
.width80 {
  width: 80px!important;
}
.width120 {
  width: 120px!important;
}
.width140 {
  width: 140px!important;
}
.width160 {
  width: 160px!important;
}
.width240 {
  width: 240px!important;
}
.width360 {
  width: 360px!important;
}
.width480 {
  width: 480px!important;
}
.input_required::after {
      content: " *";
      color: $danger;
}
.b-checkbox.checkbox:not(.button) {
    margin-right: 1.5em;
}
.min-width280 {
  min-width: 280px;
}
.field {
  .label {
    &.required::after {
      content: " *";
      color: $danger;
    }
  }
}
.is-outlined .mdi-close-circle-outline {
  color: hsl(0deg, 0%, 60%);
  font-size: 1.5rem;
}
// .is-outlined .mdi-close-circle-outline {
//     color: hsl(0deg, 0%, 50%);
// }

// タイトルヘッダ
@media (max-width: 640px) {
  .mobile-hidden {
    display: none;
  }
}

// モーダルサイズ
@media (min-width: 769px) {
  .modal-card {
    width: 780px;
  }
}
@media (min-width: 1200px) {
  .modal-card {
    width: 1200px;
  }
}
.modal-card-title {
    font-size: 1.25rem;
}
// 検索結果テーブル
.search-result {
  background-color:hsl(48, 100%, 96%) !important;
  white-space:nowrap;
  vertical-align:middle;
}
.search-result-middle {
  vertical-align: middle !important;
}
.narrow-col {
  width: 50px!important;
}
.narrow2-col {
  width: 100px!important;
}
.wide-col {
  width: 100%!important;
}
.one-third-col {
  width: 33%!important;
}
.nowrap{
  white-space:nowrap;
}

// Loading Image
.loading {
  background: transparent url('./assets/img/image_preload.gif') center no-repeat;
  height: 200px;
  width: 100%;
}

</style>
