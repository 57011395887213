import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView')
  },
  {
    path: '/crane',
    name: 'crane',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crane" */ '../views/CraneView'),
    children: [
      {
        // 施設新規登録
        name: 'facility_new',
        path: 'facility',
        children: [
          {
            // 施設編集
            name: 'facility_edit',
            path: ':facility_id',
            children: [
              {
                // 施設詳細
                name: 'facility_detail',
                path: 'detail',
              },
              {
                // 物件新規登録
                name: 'case_new',
                path: 'case',
              }
            ]
          },
        ]
      },
      {
        // 物件
        path: 'case',
        children: [
          {
            // 物件編集
            name: 'case_edit',
            path: ':case_id',
            children: [
              {
                // 物件詳細
                name: 'case_detail',
                path: 'detail',
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/train',
    name: 'train',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crane" */ '../views/TrainView'),
    children: [
      {
        // 鉄道会社新規登録
        name: 'train_company_new',
        path: 'company',
        children: [
          {
            // 鉄道会社編集
            name: 'train_company_edit',
            path: ':railway_company_code',
            children: [
              {
                // 鉄道会社詳細
                name: 'train_company_detail',
                path: 'detail',
              },
              {
                // 物件新規登録
                name: 'train_case_new',
                path: 'case',
              }
            ]
          },
        ]
      },
      {
        // 物件
        path: 'case',
        children: [
          {
            // 物件編集
            name: 'train_case_edit',
            path: ':case_id',
            children: [
              {
                // 物件詳細
                name: 'train_case_detail',
                path: 'detail',
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/njss',
    name: 'njss',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crane" */ '../views/NjssView'),
  },
  {
    path: '/bridge',
    name: 'bridge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crane" */ '../views/BridgeView'),
    children: [
      // 設計発注物件
      {
        // 新規登録
        name: 'bridge_design-case',
        path: 'design-case',
        children: [
          {
            // NJSS ⇒ 登録
            name: 'bridge_njss_to_design-case',
            path: 'njss',
            children: [
              {
                name: 'bridge_njss_to_design-case_new',
                path: ':njss_data_id',
              },
            ]
          },
          {
            // 詳細・編集
            name: 'bridge_design-case_detail',
            path: ':case_code',
          },
        ]
      },
      // 設計折込物件
      {
        name: 'bridge_design',
        path: 'design',
        children: [
          {
            // 詳細・編集
            name: 'bridge_design_detail',
            path: ':design_code',
          },
        ]
      },
      // 工事発注物件
      {
        // 新規登録
        name: 'bridge_construction-case',
        path: 'construction-case',
        children: [
          {
            // NJSS ⇒ 登録
            name: 'bridge_njss_to_construction-case',
            path: 'njss',
            children: [
              {
                name: 'bridge_njss_to_construction-case_new',
                path: ':njss_data_id',
              },
            ]
          },
          {
            // 編集・編集
            name: 'bridge_construction-case_detail',
            path: ':case_code',
          },
        ]
      },
      // 見積情報
      {
        name: 'bridge_estimate',
        path: 'estimate',
        children: [
          {
            // 詳細・編集
            name: 'bridge_estimate_detail',
            path: ':estimate_id',
          },
        ]
      },
    ]
  },
  {
    path: '/waterstop',
    name: 'waterstop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crane" */ '../views/WaterstopView'),
    children: [
      // 設計発注物件
      {
        // 新規登録
        name: 'waterstop_design-case',
        path: 'design-case',
        children: [
          {
            // NJSS ⇒ 登録
            name: 'waterstop_njss_to_design-case',
            path: 'njss',
            children: [
              {
                name: 'waterstop_njss_to_design-case_new',
                path: ':njss_data_id',
              },
            ]
          },
          {
            // 詳細・編集
            name: 'waterstop_design-case_detail',
            path: ':case_code',
          },
        ]
      },
      // 設計折込物件
      {
        name: 'waterstop_design',
        path: 'design',
        children: [
          {
            // 詳細・編集
            name: 'waterstop_design_detail',
            path: ':design_code',
          },
        ]
      },
      // 工事発注物件
      {
        // 新規登録
        name: 'waterstop_construction-case',
        path: 'construction-case',
        children: [
          {
            // NJSS ⇒ 登録
            name: 'waterstop_njss_to_construction-case',
            path: 'njss',
            children: [
              {
                name: 'waterstop_njss_to_construction-case_new',
                path: ':njss_data_id',
              },
            ]
          },
          {
            // 編集・編集
            name: 'waterstop_construction-case_detail',
            path: ':case_code',
          },
        ]
      },
      // 見積情報
      {
        name: 'waterstop_estimate',
        path: 'estimate',
        children: [
          {
            // 詳細・編集
            name: 'waterstop_estimate_detail',
            path: ':estimate_id',
          },
        ]
      },
    ]
  },
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "crane" */ '../views/NotFound')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

export default router
