<template>
  <div>
  <HeaderNav
    :disp_menu_contents="disp_menu_contents"
    :user_info="user_info"
    ></HeaderNav>
  <section class="section">
    <div class="container is-widescreen">
      <h4 class="title is-4"><img src="/static/img/favicon_192.png" width="45" height="45" alt="SIPSx3"><span class="v-adjust">&nbsp;物件管理システム</span></h4>

      <div class="container is-widescreen">
        <div class="box">
          <router-link to="bridge">
            <h5 class="subtitle is-5"><b-icon icon="bridge" size="is-medium"></b-icon><span class="v-adjust2">&nbsp;橋梁</span></h5>
          </router-link>
        </div>
        <div class="box">
          <router-link to="train">
            <h5 class="subtitle is-5"><b-icon icon="train" size="is-medium"></b-icon><span class="v-adjust2">&nbsp;鉄道</span></h5>
          </router-link>
        </div>
        <div class="box">
          <router-link to="crane">
            <h5 class="subtitle is-5"><b-icon icon="crane" size="is-medium"></b-icon><span class="v-adjust2">&nbsp;クレーン</span></h5>
          </router-link>
        </div>
        <div class="box">
          <router-link to="waterstop">
            <h5 class="subtitle is-5"><b-icon icon="water-off-outline" size="is-medium"></b-icon><span class="v-adjust2">&nbsp;止水</span></h5>
          </router-link>
        </div>
        <div class="box">
          <router-link to="njss">
            <h5 class="subtitle is-5"><b-icon icon="download-circle-outline" size="is-medium"></b-icon><span class="v-adjust2">&nbsp;NJSS</span></h5>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderNav from '@/components/HeaderNav'
import Auth from '@/mixins/auth'

export default {
  name: 'HomeView',
  components: {
    HeaderNav,
  },
  props: {
  },
  data() {
    return {
      disp_menu_contents: true,
      user_info: null,
    }
  },
  mixins: [
    Auth,
  ],
  async created() {
    this.user_info = await this.authCheck(false)
  },
  mounted() {
    this.$emit('setDispMenuContentsOn')
  },
}
</script>

<style scoped>
.v-adjust {
  vertical-align: super;
}
.v-adjust2 {
  vertical-align: text-bottom;
}
</style>
