import Vue from 'vue'
import App from './App'
import router from './router'
import Buefy from 'buefy'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import moment from "moment"
import sanitizeHTML from 'sanitize-html'
import VueScrollTo from 'vue-scrollto'
import dateUtils from './helper/date-utils'
import listUtils from './helper/list-utils'
import stringUtils from './helper/string-utils'

// Vue の起動時のプロダクションのヒント表示
Vue.config.productionTip = true
Vue.prototype.$sanitize = sanitizeHTML

Vue.use(Buefy, {
  // defaultDateFormatter: date => {
  //   return moment(date).format("YYYY-MM-DD");
  // },
  // defaultDateParser: date => {
  //   return moment(date, "YYYY-MM-DD").toDate();
  // }
})
Vue.use(VueAxios, axios)
// Vue.use(VeeValidate)
// Validator.localize('ja', ja)
Vue.use(VueScrollTo)

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

const plugin = {
  install () {
      Vue.prototype.$dateUtils = dateUtils
      Vue.prototype.$listUitls = listUtils
      Vue.prototype.$stringUitls = stringUtils
  }
}
Vue.use(plugin)

new Vue({
  router,
  render: function(createElement) {

    // axiosのプロトタイプ宣言
    Vue.prototype.$axios = axios
    // Cookieの使用を可とする
    axios.defaults.withCredentials = true

    // API Url
    const server_url = process.env.VUE_APP_API_URL
    console.log('baseApiUrl: ' + server_url)
    this.$axios.defaults.baseURL = server_url
    Vue.prototype.$server_url = server_url

    // Debug Mode
    const debug = process.env.VUE_APP_DEBUG
    console.log('debugMode: ' + debug)
    Vue.prototype.$debug = debug

    // Version
    const version = this.$el.getAttribute('version')
    Vue.prototype.$version = version

    return createElement(App,
      {
        props: {
        }
      })
  }
}).$mount('#app')
